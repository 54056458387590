import * as React from "react"

const PhoneIcon = props => (
  <svg
    width={props.size || 24}
    height={props.size || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask
      id="a"
      maskUnits="userSpaceOnUse"
      x={7}
      y={2}
      width={10}
      height={6}
      fill="#000"
    >
      <path fill="#fff" d="M7 2h10v6H7z" />
      <path d="M13.333 5h-2.666c-.155 0-.233 0-.296-.017a.5.5 0 0 1-.354-.354C10 4.566 10 4.49 10 4.333c0-.077 0-.116.008-.148a.25.25 0 0 1 .177-.176c.032-.009.07-.009.148-.009h3.334c.077 0 .116 0 .148.009a.25.25 0 0 1 .177.176c.008.032.008.07.008.148 0 .155 0 .233-.017.296a.5.5 0 0 1-.354.354C13.566 5 13.49 5 13.333 5Z" />
    </mask>
    <path
      d="m10.017 4.63-1.932.517 1.932-.518Zm.354.353-.518 1.932.518-1.932Zm3.612-.354-1.932-.517 1.932.517Zm-.354.354.518 1.932-.518-1.932Zm.363-.798 1.931-.517-1.931.517Zm-.177-.176-.518 1.931.518-1.931Zm-3.806.176 1.931.518-1.931-.518Zm.176-.176.518 1.931-.518-1.931ZM10.667 7h2.666V3h-2.666v4Zm3-5h-3.334v4h3.334V2ZM8 4.333c0 .027-.017.432.085.814l3.864-1.035c.044.166.05.292.051.313V4.333H8ZM10.667 3H10.575c.02 0 .147.007.313.051L9.853 6.915c.382.102.787.085.814.085V3ZM8.085 5.147a2.5 2.5 0 0 0 1.768 1.768l1.035-3.864a1.5 1.5 0 0 1 1.06 1.06L8.086 5.148ZM12 4.333V4.425c0-.02.007-.147.051-.313l3.864 1.035c.102-.382.085-.787.085-.814h-4ZM13.333 7c.027 0 .432.017.814-.085L13.112 3.05c.166-.044.292-.05.313-.051H13.332v4Zm-1.282-2.888a1.5 1.5 0 0 1 1.06-1.06l1.036 3.863a2.5 2.5 0 0 0 1.768-1.768L12.05 4.112ZM16 4.333c0-.02 0-.102-.003-.182a2.26 2.26 0 0 0-.074-.483L12.06 4.703a1.752 1.752 0 0 1-.059-.37L12 4.3v.033h4ZM13.667 6h.033l-.033-.001a1.748 1.748 0 0 1-.37-.059l1.035-3.863a2.255 2.255 0 0 0-.483-.074C13.769 2 13.687 2 13.667 2v4Zm2.256-2.332a2.25 2.25 0 0 0-1.59-1.591L13.296 5.94a1.75 1.75 0 0 1-1.237-1.237l3.863-1.035ZM12 4.333V4.3L12 4.333c-.002.044-.01.188-.059.37L8.077 3.668c-.058.214-.07.393-.074.483C8 4.231 8 4.313 8 4.333h4ZM10.333 2c-.02 0-.103 0-.182.003-.09.004-.27.016-.483.074l1.035 3.863a1.748 1.748 0 0 1-.37.059L10.3 6h.033V2Zm1.607 2.703a1.75 1.75 0 0 1-1.237 1.237L9.668 2.077a2.25 2.25 0 0 0-1.591 1.59l3.863 1.036Z"
      fill={props.color || "#CCD2E3"}
      mask="url(#a)"
    />
    <rect
      x={6}
      y={3}
      width={12}
      height={18}
      rx={2}
      stroke={props.color || "#CCD2E3"}
      strokeWidth={2}
    />
    <circle cx={12} cy={18} r={1} fill={props.color || "#CCD2E3"} />
  </svg>
)

export default PhoneIcon
