import React from "react"

const HeroBase = ({ children }) => {
  return (
    <div
      style={{
        width: "100%",
        height: "calc(100vh - 200px)",
        // backgroundColor: "#F9F9FB",
        // backgroundImage: `url(${backgroundImage})`,
        // backgroundRepeat: "no-repeat",
        // backgroundSize: "contain",
        // backgroundPositionY: "center",
        // backgroundPositionX: "100%",
        // backgroundBlendMode: "darken",
        minHeight: "520px",
        position: "relative",
        overflow: "hidden",
      }}
    >
      {children}
    </div>
  )
}

export default HeroBase
