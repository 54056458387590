import * as React from "react"

import DesktopIcon from "./../components/icons/desktopIcon"
import PhoneIcon from "../components/icons/phoneIcon"
import PenIcon from "../components/icons/penIcon"

export const specialites = [
  {
    title: "UI/UX Design",
    decription:
      "Development of modern user-friendly design for web and mobile applications. A single style, a selection of fonts and photos, creating a logo are also possible.",
    color: "#64b5f6",
    logo: <PenIcon color="#fff" size={32} />,
  },
  {
    title: "Web developing",
    decription:
      "Development of complex modern web applications using the latest technologies. Business card website, e-commerce, blog, interactive application, social platforms and more. Adaptive design, search engine optimization, database development, design are also present.",
    color: "#1e88e5",
    logo: <DesktopIcon color="#fff" size={32} />,
  },
  {
    title: "Mobile Developing",
    decription:
      "Development of complex modern mobile applications using the latest technologies. Design, development of databases, design, publication in the AppStore and Google Play are also present.",
    color: "#1565c0",
    logo: <PhoneIcon color="#fff" size={32} />,
  },
]
