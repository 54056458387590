import * as React from "react"
import { Link, useStaticQuery } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Statistic from "../components/statistic"
import Title from "../components/title"
import Speciality from "../components/speciality"
import Technology from "../components/technology"
import { graphql } from "gatsby"
import HeroBase from "../components/heroBase"
import mcwImage from "../images/mcw2.png"
import gmcImage from "../images/gmc.png"
import ghImage from "../images/gh.png"
import pfImage from "../images/panamaflag.png"
import paImage from "../images/panama.webp"
import Button from "./../components/button"
import Block from "../components/block"
import Labels from "../components/labels"
import HeroData from "../components/heroData"
import "./index.css"

import { specialites } from "../data/specialities"
import { labelData } from "../data/technologies"

const mcwLabels = [
  "aw",
  "dk",
  "fb",
  "fi",
  "gb",
  "gh",
  "js",
  "nd",
  "ng",
  "pg",
  "rd",
  "re",
  "rs",
  "ts",
  "js",
  "ht",
  "cs",
]

const gmcLabels = ["fb", "gh", "js", "nd", "rd", "re", "rs", "mu", "ht", "cs"]

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { relativePath: { regex: "images/technologies/" } }) {
        edges {
          node {
            id
            base
            publicURL
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <Seo
        title="Home"
        description={"Aleksandr Baikalov. Fullstack Software Engineer"}
      />
      <HeroBase>
        <HeroData
          title={"Fullstack Software Engineer"}
          description={`Development of websites and mobile applications, including design,
                engineering, development and deployment`}
        >
        </HeroData>

        <div className="main-image-wrapper">
          <img className="main-image" src={paImage} />
          <img
            className="contain-image"
            src={pfImage}
            style={{ opacity: 0.3 }}
          />
        </div>
      </HeroBase>

      <Statistic />
      <Title
        title={"Specialities"}
        subtitle="Not only websites. There are plenty options. Choose what you want"
      />
      <div className="specs">
        {specialites.map(item => (
          <Speciality
            title={item.title}
            description={item.decription}
            color={item.color}
            logo={item.logo}
          />
        ))}
      </div>

      <Title
        title={"My works"}
        subtitle="See my recent projects with demo and links"
      />
      <Block>
        <HeroBase>
          <HeroData
            color={"#2196F3"}
            title={"Car wash aggregator app"}
            description={
              "Mobile application-aggregator for remote registration for car wash services"
            }
          >
            <div
              style={{
                display: "flex",
              }}
            >
              <div className="hero-buttons">
                <Button
                  text="Website"
                  style={{ marginBottom: "20px" }}
                  href="https://megacarwash.net/"
                />
                <Button
                  backgroundColor="#FF0000"
                  text="Youtube"
                  href="https://www.youtube.com/watch?v=ZonTNczrThM"
                />
              </div>
              <Labels
                edges={data.allFile.edges.filter(edge =>
                  mcwLabels.includes(edge.node.base.split(".")[0])
                )}
              />
            </div>
            <div className="main-image-wrapper">
              <img src={mcwImage} className="contain-image" />
            </div>
          </HeroData>
        </HeroBase>
      </Block>
      <Block>
        <HeroBase>
          <HeroData
            title={"Google maps analogue"}
            description={
              "An analogue of Google maps, developed from scratch. It is possible to view, add and edit information about objects on the map and add photos"
            }
            color={"#0ACF83"}
          >
            <div
              style={{
                display: "flex",
              }}
            >
              <div className="hero-buttons">
                <Button
                  text="Live demo"
                  style={{ marginBottom: "20px" }}
                  backgroundColor="#0ACF83"
                  href={"https://g-maps-clone.web.app/"}
                />
                <Button
                  text="Github"
                  href={"https://github.com/AlexanderBaikal/google-maps-clone"}
                  backgroundColor="#000"
                />
              </div>
              <Labels
                edges={data.allFile.edges.filter(edge =>
                  gmcLabels.includes(edge.node.base.split(".")[0])
                )}
              />
            </div>
          </HeroData>
          <div className="main-image-wrapper">
            <img src={gmcImage} className="main-image" />
          </div>
        </HeroBase>
      </Block>
      <Block>
        <HeroBase>
          <HeroData
            title={"Other projects"}
            description={
              "Public repositories on github with demo and source code"
            }
            backgroundImage={ghImage}
            color={"#000"}
          >
            <div
              style={{
                display: "flex",
              }}
            >
              <Button
                href={"https://github.com/AlexanderBaikal"}
                text="Show more"
                backgroundColor="#000"
              />
            </div>
          </HeroData>
          <div className="main-image-wrapper">
            <img src={ghImage} className="contain-image" />
          </div>
        </HeroBase>
      </Block>

      <Title title={"Techologies"} subtitle="A list of technologies" />
      <div className="techs">
        {data.allFile.edges.map(edge => (
          <Technology
            key={edge.node.publicURL}
            img={edge.node.publicURL}
            title={labelData[edge.node.base.split(".")[0]]?.title}
            color={labelData[edge.node.base.split(".")[0]]?.color}
          />
        ))}
      </div>
    </Layout>
  )
}

const styles = {}

export default IndexPage
