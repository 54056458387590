import React from "react"
import "./heroData.css"

const HeroData = ({ title, description, children, color = "000" }) => {
  return (
    <div className="herodata">
      <div
        className="hero-title"
        style={{
          color,
        }}
      >
        {title}
      </div>
      <div className="hero-description" style={{}}>
        {description}
      </div>
      {children}
    </div>
  )
}

export default HeroData
