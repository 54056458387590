import React from "react"
import "./title.css"

const Title = ({ title, subtitle = null }) => {
  return (
    <div
      style={{
        marginBottom: "56px",
      }}
    >
      <div className="title">{title}</div>
      {subtitle ? (
        <div
          style={{
            fontSize: "16.5px",
            color: "#A0A0A0",
            textAlign: "center",
            marginTop: "19px",
          }}
        >
          {subtitle}
        </div>
      ) : null}
    </div>
  )
}

export default Title
