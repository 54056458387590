import * as React from "react"

const PenIcon = props => (
  <svg
    width={props.size || 24}
    height={props.size || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x={4}
      y={16}
      width={16}
      height={4}
      rx={2}
      stroke={props.color || "#CCD2E3"}
      strokeWidth={2}
    />
    <path
      d="M4.91 11.364 8 16h8l3.09-4.636c.436-.653.654-.98.618-1.335-.035-.356-.312-.633-.867-1.188L12 2 5.16 8.84c-.556.556-.833.833-.868 1.189-.036.356.182.682.617 1.335Z"
      stroke={props.color || "#CCD2E3"}
      strokeWidth={2}
    />
    <circle
      cx={12}
      cy={11}
      r={2}
      stroke={props.color || "#CCD2E3"}
      strokeWidth={2}
    />
    <path d="M12 2v7" stroke={props.color || "#CCD2E3"} strokeWidth={2} />
  </svg>
)

export default PenIcon
