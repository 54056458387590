import React from "react"
import "./button.css"

const Button = ({
  text,
  color = "#fff",
  backgroundColor = "#2196F3",
  style = {},
  href = null,
}) => {
  return (
    <a href={href} target="_blank" style={{ textDecoration: "none", color }}>
      <div
        className="button"
        style={{
          backgroundColor,
          color,
          ...style,
        }}
      >
        {text}
      </div>
    </a>
  )
}

export default Button
