import React from "react"
import { labelData } from "../data/technologies"
import "./labels.css"

const Labels = ({ edges }) => {
  return (
    <div style={styles.labels} className="labels">
      {edges.map(edge => (
        <div style={styles.wrapper}>
          <img
            key={edge.node.publicURL}
            style={styles.label}
            src={edge.node.publicURL}
            alt={labelData[edge.node.base.split(".")[0]]?.title}
          />
        </div>
      ))}
    </div>
  )
}
const styles = {
  labels: {
    // display: "flex",
    // alignItems: "center",
    // flexWrap: "wrap",
  },
  label: {
    maxHeight: "40px",
    // margin: "0 10px",
  },
  wrapper: {
    height: "49px",
    display: "flex",
    alignItems: "center",
    width: "70px",
    justifyContent: "center",
    boxSizing: "border-box",
  },
}
export default Labels
