export const labelData = {
  ae: {
    title: "After Effects",
    color: "#00005B",
  },
  fb: {
    title: "Firebase",
    color: "#FFCA2B",
  },
  ts: {
    title: "Typescript",
    color: "#3178C6",
  },
  gh: {
    title: "Github",
    color: "#181616",
  },
  aw: {
    title: "AWS",
    color: "#181616",
  },
  pg: {
    title: "PostgreSQL",
    color: "#316192",
  },
  fi: {
    title: "Figma",
    color: "#0ACF83",
  },
  nd: {
    title: "Node.js",
    color: "#75B75F",
  },
  re: {
    title: "React",
    color: "#00D8FF",
  },
  gb: {
    title: "Gatsby",
    color: "#663399",
  },
  dk: {
    title: "Docker",
    color: "#2497ED",
  },
  rd: {
    title: "Redux",
    color: "#764ABC",
  },
  mu: {
    title: "Material UI",
    color: "#0288D1",
  },
  bt: {
    title: "Bootstrap",
    color: "#7411F6",
  },
  py: {
    title: "Python",
    color: "#FFD040",
  },
  fl: {
    title: "Flask",
    color: "#000",
  },
  nx: {
    title: "Next.js",
    color: "#000",
  },
  wg: {
    title: "WebGL",
    color: "#990000",
  },
  ai: {
    title: "Illustrator",
    color: "#FF9A00",
  },
  pr: {
    title: "Premiere",
    color: "#9999FF",
  },
  ng: {
    title: "Nginx",
    color: "#009639",
  },
  rs: {
    title: "Redux Saga",
    color: "#89D96D",
  },
  ht: {
    title: "HTML5",
    color: "#F16529",
  },
  cs: {
    title: "CSS3",
    color: "#2965F1",
  },
  js: {
    title: "Javascript",
    color: "#F0DB4F",
  },
  je: {
    title: "Jest",
    color: "#99425B",
  },

  vu: {
    title: "Vue.js",
    color: "#41B883",
  },
  ns: {
    title: "Nest.js",
    color: "#E0234E",
  },
  vx: {
    title: "Vuex",
    color: "#4FC08D",
  },
  tl: {
    title: "Testing Library",
    color: "#FC4544",
  },
}
