import React from "react"
import "./speciality.css"

const Speciality = ({ logo = null, title, description, color }) => {
  return (
    <div
      style={{
        background: "#FBFAFF",
        borderRadius: "5px",
        padding: "30px 38px",
      }}
    >
      <div
        style={{
          width: "68px",
          height: "68px",
          boxSizing: "border-box",
          marginBottom: "14px",
        }}
      >
        <div
          style={{
            width: "100%",
            height: "100%",
            border: `2px solid ${color}`,
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "48px",
              height: "48px",
              background: color,
              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {logo}
          </div>
        </div>
      </div>
      <div className="spec-title">{title}</div>
      <div
        style={{
          color: "#6A6A6A",
          fontSize: "16.5px",
          lineHeight: "170%",
        }}
      >
        {description}
      </div>
    </div>
  )
}

export default Speciality
