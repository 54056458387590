import React from "react"
import "./statistic.css"

const stats = [
  {
    value: "10+",
    description: "projects completed",
  },
  {
    value: "1B+",
    description: "lines of code",
  },
  {
    value: "4+",
    description: "years of experience",
  },
  {
    value: "10+",
    description: "Happy clients",
  },
]

const Statistic = () => {
  return (
    <div style={{ marginTop: "50px", marginBottom: "100px" }}>
      <div className="statistic">
        {stats.map(item => (
          <div className="stat-item">
            <div
              style={{
                fontWeight: "700",
                fontSize: "42px",
                // color: "rgb(33, 150, 243)",
              }}
            >
              {item.value}
            </div>
            <div
              style={{
                color: "#BBBDCC",
                textTransform: "uppercase",
                fontSize: "12px",
              }}
            >
              {item.description}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Statistic
